import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Loader"], { loading: $setup.stepLoading }, null, 8, ["loading"]),
    (!$setup.stepLoading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["request-for-quote", {sent: $setup.step === 4}])
        }, [
          _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
          ($setup.showSelectRfqFileModal)
            ? (_openBlock(), _createBlock($setup["SelectRfqFileModal"], {
                key: 0,
                show: $setup.showSelectRfqFileModal,
                "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showSelectRfqFileModal) = $event)),
                rfqFiles: $setup.rfqFiles,
                onRfqFileSelected: $setup.onRfqFileSelected,
                onCancel: $setup.onRfqFileSelectCanceled
              }, null, 8, ["show", "rfqFiles"]))
            : _createCommentVNode("", true),
          ($setup.step === 1)
            ? (_openBlock(), _createBlock($setup["UploadProjectFiles"], {
                key: 1,
                onFilesPicked: $setup.onFilesPicked
              }))
            : ($setup.step === 2)
              ? (_openBlock(), _createBlock($setup["MatchColumns"], {
                  key: 2,
                  rfqFile: $setup.rfqFiles[0],
                  partsUploaded: $setup.partList.length,
                  onColumnsMatched: $setup.onColumnsMatched
                }, null, 8, ["rfqFile", "partsUploaded"]))
              : ($setup.step === 4)
                ? (_openBlock(), _createBlock($setup["RequestHasBeenSentMessage"], {
                    key: 3,
                    rfqNumber: $setup.rfq.number
                  }, null, 8, ["rfqNumber"]))
                : _createCommentVNode("", true),
          _createVNode($setup["PartList"], {
            show: $setup.step === 3,
            partList: $setup.partList,
            threeDFiles: $setup.threeDFiles,
            pdfFiles: $setup.pdfFiles,
            unconnectedThreeDFiles: $setup.modelAdditionalList,
            unconnectedPdfFiles: $setup.drawingAdditionalList,
            rfq: $setup.rfq,
            gotRfqList: !!$setup.rfqFiles.length || $setup.repetitiveRfqList,
            partOperationLoadingIndex: $setup.partOperationLoadingIndex,
            filesForUpload: $setup.filesForUpload,
            savedAtTime: $setup.savedAtTime,
            maxQuantitiesLength: $setup.maxQuantitiesLength,
            additionalFiles: $setup.additionalFiles,
            additionalFilesLoadingIndexes: $setup.additionalFilesLoadingIndexes,
            onRfqListPicked: $setup.onRfqListPicked,
            onExtraFilesPicked: $setup.onExtraFilesPicked,
            onPartNumberUpdated: $setup.onPartNumberUpdated,
            onQuantityAdded: $setup.onQuantityAdded,
            onRemoveQuantity: $setup.onRemoveQuantity,
            onScheduleQuantity: $setup.onScheduleQuantity,
            onScheduleRemark: $setup.onScheduleRemark,
            onRemarkAdded: $setup.onRemarkAdded,
            onRemoveRemark: $setup.onRemoveRemark,
            onRemovePart: $setup.onRemovePart,
            onExtra3DFilesPicked: $setup.onExtra3DFilesPicked,
            onExtraDrawingsPicked: $setup.onExtraDrawingsPicked,
            on3dSelected: $setup.on3dSelected,
            onDrawingSelected: $setup.onDrawingSelected,
            onCreatePartByAdditionalFile: $setup.onCreatePartByAdditionalFile,
            onRemoveAdditionalFile: _cache[1] || (_cache[1] = ($event: any) => ($setup.removeAdditionalFileEvent(), $setup.onRemoveAdditionalFile($event))),
            onRetryAdditionalFileUpload: _cache[2] || (_cache[2] = ($event: any) => ($setup.onRetryAdditionalFileUpload($event, $setup.setFilesToUpload))),
            onRetry3dUpload: $setup.onRetry3dUpload,
            onRetryDrawingUpload: $setup.onRetryDrawingUpload,
            onSetToLoad: $setup.onSetToLoad,
            onStartedLoading: $setup.onStartedLoadingFile,
            onFinishedLoading: $setup.onFinishedLoadingEvent,
            onModelProcessingUpdate: $setup.onModelProcessingUpdate,
            onErrorLoading: $setup.onErrorLoadingFile,
            onUploadFilesFinished: $setup.onUploadFilesFinished,
            onProjectNameUpdate: $setup.onProjectNameUpdate,
            onSentRequest: $setup.onSentRequest,
            onDeleteRequest: $setup.deleteRequest
          }, null, 8, ["show", "partList", "threeDFiles", "pdfFiles", "unconnectedThreeDFiles", "unconnectedPdfFiles", "rfq", "gotRfqList", "partOperationLoadingIndex", "filesForUpload", "savedAtTime", "maxQuantitiesLength", "additionalFiles", "additionalFilesLoadingIndexes", "onCreatePartByAdditionalFile", "onSetToLoad"])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}